export default {
  'original_password': 'Mot de passe original',
  'new_password': 'Nouveau mot de passe',
  'enter_the_original_password': 'Entrez le mot de passe original',
  'enter_the_original_password_login': 'Entrez le mot de passe original',
  'enter_the_new_password_(enter_8_or_more_characters)': 'Entrez le nouveau mot de passe (entrez 8 caractères ou plus)',
  'confirm_password': 'Confirmez le mot de passe',
  're_enter_the_new_password': 'Re-entrez le nouveau mot de passe',
  'confirm_modification': 'Confirmez la modification',
  'enter_new_password_enter_6_digit_number': 'Entrez le nouveau mot de passe (entrez un nombre à 6 chiffres)',
  'recharge_amount': 'Montant de recharge',
  'reason_for_rejection': 'Raison du rejet',
  'time': 'heure',
  'in_review': 'En revue',
  'completed': 'Terminé',
  'rejected': 'Rejeté',
  'current_browser_does_not_support_copy': 'Le navigateur actuel ne supporte pas la copie',
  'copy_successful': 'Copie réussie',
  'home': 'Accueil',
  'liquidity_pool': 'Pool de liquidité',
  'me': 'Moi',
  'recharge': 'Recharger',
  'withdraw': 'Retirer',
  'buy_second_contract': 'Acheter un deuxième contrat',
  'settle_second_contract': 'Régler le deuxième contrat',
  'buy_lever': 'Acheter un levier',
  'settle_lever': 'Régler le levier',
  'stake_lp': 'Déposer LP',
  'earnings_lp': 'Gains LP',
  'settlement_lp': 'Règlement LP',
  'admin': 'Récompenses de niveau',
  'withdraw_amount': 'Montant à retirer',
  'withdrawal_address': 'Adresse de retrait',
  'rejection_reason': 'Raison du rejet',
  'under_review': 'En cours de revue',
  'invitation_code': 'Code d\'invitation',
  'balance': 'Solde',
  'today': 'Aujourd\'hui',
  'deposit': 'Dépôt',
  'real_name_authentication': 'Authentification nom réel',
  'billing_history': 'Historique de facturation',
  'second_contract_order': 'Commande de deuxième contrat',
  'liquidity_pool_order': 'Commande de pool de liquidité',
  'my_team': 'Mon équipe',
  'change_password': 'Changer le mot de passe',
  'logout': 'Déconnexion',
  'cancel': 'Annuler',
  'document_type': 'Type de document',
  'real_name': 'Nom réel',
  'please_enter_real_name': 'Veuillez entrer le nom réel',
  'please_enter_document_number': 'Veuillez entrer le numéro de document',
  'document_number': 'Numéro de document',
  'document_photo': 'Photo de document',
  'front': 'Avant',
  'back': 'Arrière',
  'submit': 'Soumettre',
  'certification_passed': 'Certification réussie',
  'identity_card': 'Carte d\'identité',
  'passport': 'Passeport',
  'login_password': 'Mot de passe de connexion',
  'transaction_password': 'Mot de passe de transaction',
  'low': 'Basse',
  'high': 'Haute',
  'open': 'Ouvert',
  'close': 'Fermé',
  'buy_rise': 'Acheter à la hausse',
  'buy_fall': 'Acheter à la baisse',
  'return_rate': 'Taux de retour',
  'capital': 'Capital',
  'is_leverage': 'Est-ce un levier',
  'leverage_ratio': 'Ratio de levier',
  'amount': 'Montant',
  'available_amount': 'Montant disponible',
  'expected_return': 'Retour attendu',
  'transaction_fee': 'Frais de transaction',
  'confirmation': 'Confirmation',
  'price': 'Prix',
  'direction': 'Direction',
  'rise': 'Hausse',
  'fall': 'Baisse',
  'enter_payment_password': 'Entrez le mot de passe de paiement',
  'in_progress': 'En cours',
  'final_price': 'Prix final',
  'leverage': 'Levier',
  'handling_fee': 'Frais de traitement',
  'expected_profit_loss': 'Gain/Perte attendu',
  'creation_time': 'Heure de création',
  'no_more': 'Plus de',
  'has_ended': 'Est terminé',
  'actual_profit_and_loss': 'Bénéfice et perte réels',
  'end_time': 'Heure de fin',
  'billing_record': 'Enregistrement de facturation',
  'currency_pair': 'Paire de devises',
  'latest_price': 'Dernier prix',
  'price_change_percentage': 'Pourcentage de changement de prix',
  'staking_liquidity_pool': 'Français',
  'participate_in_staking': 'Participer à la staking',
  'current_total_staking': 'Total actuel de la staking',
  'cumulative_rewards': 'Récompenses cumulées',
  'add_liquidity': 'Ajouter de la liquidité',
  'daily_yield_rate': 'Taux de rendement quotidien',
  'staking_limit': 'Limite de staking',
  'day': 'Jour',
  'staking_time': 'Heure de staking',
  'input_staking_amount': 'Montant de staking à saisir',
  'staking_amount': 'Montant de staking',
  'total_return_rate': 'Taux de retour total',
  'confirm': 'Confirmer',
  'liquidity_pool_staking_order': 'Commande de staking de pool de liquidité',
  'daily_profit': 'Profit quotidien',
  'due_date': 'Date d\'échéance',
  'order_id': 'Numéro de commande',
  'expiration_time': 'Heure d\'expiration',
  'cancel_order': 'Annuler la commande',
  'confirm_cancellation': 'Confirmer l\'annulation',
  'penalty_rate': 'Taux de pénalité',
  'expected_arrival_amount': 'Montant attendu d\'arrivée',
  'login_account': 'Compte de connexion',
  'input_email_account': 'Entrer le compte email',
  'email': 'Email',
  'password': 'Mot de passe',
  'input_login_password': 'Entrer le mot de passe de connexion',
  'login': 'Se connecter',
  'register_now': 'S\'inscrire maintenant',
  'no_account_yet': 'Pas encore de compte',
  'account': 'Compte',
  'invitation_time': 'Heure de l\'invitation',
  'level': 'Niveau',
  'please_select_withdrawal_currency': 'Veuillez sélectionner la devise de retrait',
  'withdrawal_amount': 'Montant de retrait',
  'please_enter_withdrawal_amount': 'Veuillez entrer le montant de retrait',
  'please_enter_withdrawal_address': 'Veuillez entrer l\'adresse de retrait',
  'please_enter_transaction_password': 'Veuillez entrer le mot de passe de transaction',
  'estimated_actual_arrival': 'Arrivée estimée',
  'please_select_recharge_currency': 'Veuillez sélectionner la devise de recharge',
  'recharge_address': 'Adresse de recharge',
  'please_enter_recharge_amount': 'Veuillez saisir le montant de recharge',
  'please_enter_recharge_hash': 'Veuillez saisir le hash de recharge',
  'recharge_hash': 'Hash de recharge',
  'upload_recharge_proof_payment_screenshot': 'Télécharger la preuve de recharge (capture d\'écran de paiement)',
  'please_authenticate_first': 'Veuillez vous authentifier d\'abord',
  'create_account': 'Créer un compte',
  'verification_code': 'Code de vérification',
  'enter_verification_code': 'Entrez le code de vérification',
  'get_verification_code': 'Obtenir le code de vérification',
  'funds_password': 'Mot de passe des fonds',
  'register': 'S\'inscrire',
  'set_funds_password_6_digit_number': 'Définir le mot de passe des fonds (6 chiffres)',
  'set_login_password_input_8_plus_characters': 'Définir le mot de passe d\'accès (entrez 8+ caractères)',
  'please_enter_email': 'Veuillez saisir votre adresse électronique',
  "announcement": "Annonce",
  "announcement_details": "Détails de l'annonce",
  "times": "Fois",
  "the_purchase_period_has_ended_please_make_a_new_selection": "La période d'achat est terminée, veuillez faire une nouvelle sélection",

  "index": {
    button: "Start Trading",
    banTip: "Uniswap est un échange de cryptomonnaie décentralisé qui utilise un ensemble de contrats intelligents pour créer des pools de liquidités permettant d'exécuter des transactions.",
    tip1: "Uniswap a été créé le 2 novembre 2018 par Hayden Adams, un ancien ingénieur mécanique chez Siemens",
    tip2: "Uniswap est un protocole financier décentralisé pour échanger des cryptomonnaies et des jetons ; il est alimenté par un réseau blockchain exécutant un logiciel open source. Cela contraste avec les plateformes d'échange de cryptomonnaies gérées par des entreprises centralisées",
    tip3: "Uniswap agit comme un market maker automatisé, utilisant des pools de liquidité pour exécuter les ordres au lieu de dépendre de market makers traditionnels, dans le but de créer un marché plus efficace",
    tip4: "Bien que le commerce d'actifs numériques basé sur la blockchain existe depuis 2009, il y a toujours eu un écart de fonctionnalité entre (1) le commerce sur chaîne et (2) les plateformes d'échange centralisées basées sur la confiance. Maintenant, le succès de l'échange décentralisé Uniswap a comblé cette lacune. Le market maker automatisé à produit constant d'Uniswap permet d'échanger des jetons blockchain sans dépendre de market makers, d'achats ou de ventes. Cela renverse des siècles de pratique sur les marchés financiers et constitue la pierre angulaire d'un nouveau système financier décentralisé. Nous appliquons les méthodes ARDL et VAR à un ensemble de données de 999 heures de transactions Uniswap et concluons que sa simplicité permet aux fournisseurs de liquidité et aux arbitragistes de s'assurer que les ratios de réserve correspondent aux prix des paires de trading. Nous constatons que les variations des réserves d'Ether Granger causent des variations des réserves de USDT."
  },
  "newHome": {
    Cryptos: "Criptovalute",
    Forex: "Forex",
    Gold: "Oro",
    futures: "futures",
  },
  "newPool": {
    StartStaking: "Inizia a staking"
  },
  "newMe": {
    balance: "Saldo del conto",
    saveQr:"Salva il codice QR",
    TopUp:"Ricompensa per ricarica"
  },
  "newLevel": "Livello",
  "Cumulative":"Montante cumulato attuale",
  "levelSets":"Ricarica per aggiornamento",
  "kefu":"Contatta il cliente servizio per ricevere",
  "levelInfo":{
    infoLv1: "Sblocca la ricarica, prelievo VIP",
    infoLv2: "Sblocca il bonus VIP mensile di 888",
    infoLv3: "Gode dei guadagni fissi quotidiani del 0.001% dal portafoglio di liquidità della piattaforma",
    toUpUsd:"Objectif de recharge {name}"
  },
  News:"Notizie",

  StayTuned: "Stay tuned",
  develop: "En cours de développement, merci de patienter ~ ~",
  "赠送": "Cadeau",
  "大礼包": "coffret cadeau",
  "联系客服领取": "Demandez au service client",

  meCard: "Ma carte bancaire",
  cardAdmin: "Gestion de la carte bancaire",
  newCard: {
    cardName: "Nom de la banque",
    cardNamePla: "Veuillez saisir le nom de la banque",
    number: "Numéro de compte du bénéficiaire",
    numberPla: "Veuillez saisir le numéro de compte du bénéficiaire",
    name: "Nom du bénéficiaire",
    namePla: "Veuillez saisir le nom du bénéficiaire",
    save: "Enregistrer",
    balance: "Solde du compte",
    cardInfos: "Informations de la carte bancaire",
    customer_service: "Veuillez contacter le service clientèle, horaires de retrait: 11:00 - 23:00",
    addCardInfo: "Veuillez d'abord ajouter les informations de la carte bancaire"
  },
  status: "État",
  Order: "Commande",

  banTip2:
      "Il s'agit d'un projet open source qui entre dans la catégorie des produits DeFi (finance décentralisée) car il utilise des contrats intelligents pour faciliter les échanges au lieu d'échanges centralisés. Le protocole facilite les échanges automatisés entre les jetons de crypto-monnaie sur la blockchain Ethereum grâce à l'utilisation de contrats intelligents. ",
  langeSet: "Sélection de langue",
  Sure: "Bien sûr",
  createAcount: {
    have: "J'ai déjà un compte",
    goLogin: "Aller se connecter"
  },
  Name: "Nom",
  total_revenue: "Rendement total",
  Return: "Retour",
  personal: "Centre personnel",
  operate: "Opérer",
  information: "Information",

  infoRegister:"Veuillez contacter le service client pour confirmer que l'adresse de recharge est correcte avant de transférer de l'argent."
};
